<template>
  <div class="public_css_module videoPlayPage">
    <div class="videoPlayDiv">

      <div class="videoPlay_left">
        <Ckplayer v-if="isShowVideoBox" :videoSrc="currentVideo.videopath"></Ckplayer>
      </div>

      <div class="videoPlay_right">
        <div class="videoPlay_item" v-for="(item,index) in videoList" :key="index">
          <div class="img_div" :class="{ 'active':item.id == currentVideo.id }" @click="handleEvent(item)">
            <img :src="item.coverpath" alt="">
            <div v-if="item.id == currentVideo.id" class="mask_div">
              <div class="mask_box">
                <div class="inco_div">
                  <img src="@/assets/imgFIle_3/playing.gif" alt="">
                </div>
                正在播放
              </div>
            </div>
            <div class="time_box">00:{{item.videotime}}</div>
          </div>
          <div class="name_div">
            <span class="ellipsis_1"> {{item.title}} </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import videotest from "./videotest.json";
export default {
  data() {
    return {
      currentVideo: "",
      isShowVideoBox: false,
      videoList: [],
    }
  },
  computed: {
  },

  components: {},

  methods: {
    // 切换视频
    handleEvent(item) {
      this.isShowVideoBox = false;
      this.$nextTick(() => {
        this.isShowVideoBox = true;
        this.currentVideo = item;
      })
    },
  },

  destroyed() {
  },

  mounted() {
    this.videoList = videotest?.data?.list;
    this.currentVideo = this.videoList[0];
    this.isShowVideoBox = true;
  },

  created() {
  },
}
</script>

<style lang="scss" scoped>
.videoPlayPage {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #141518;
}
.videoPlayDiv {
  height: 802px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   border: 1px solid #ccc;
  //   margin-top: 20px;
  > div {
    height: 100%;
  }
}
.videoPlay_left {
  width: 1184px;
  background-color: #000;
}
.videoPlay_right {
  overflow-y: scroll;
  padding-left: 38px;
  padding-right: 10px;
  .videoPlay_item {
    width: 284px;
    .img_div {
      width: 284px;
      height: 160px;
      border-radius: 8px;
      overflow: hidden;
      box-sizing: border-box;
      border: 3px solid #141518;
      position: relative;
      cursor: pointer;
      &.active {
        border: 3px solid rgba(255, 130, 1, 1);
      }
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .mask_div {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .mask_box {
          width: 106px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: rgba(255, 130, 1, 1);
          border-radius: 8px;
          font-size: 14px;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          .inco_div {
            width: 21px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            img {
              width: 21px;
              height: 15px;
            }
          }
        }
      }
      .time_box {
        position: absolute;
        bottom: 6px;
        left: 6px;
        width: 66px;
        height: 30px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: rgba(255, 255, 255, 1);
      }
    }
    .name_div {
      height: 68px;
      padding-top: 12px;
      padding-bottom: 58px;
      box-sizing: border-box;
      span {
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>